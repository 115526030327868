<template>
  <v-card>
    <!-- <s-select-definition hidden @change="changeTank($event)" :def="1249" /> -->
    <s-toolbar label="Armados de Tanques" dark color="#8e8f91"></s-toolbar>
    <v-container>
      <!-- <v-card> -->
        <!-- <v-row justify="center" style="margin:auto">
					<div class="col-lg-3 col-md-4 col-sm-4">
						<b>
							<v-text-field
								append-icon="mdi-magnify"
								label="Buscar"
								single-line
								hide-details
							></v-text-field>
						</b>
					</div>

					<v-col cols="6" lg="3" md="3">
						<s-date label="Fecha Inicio"></s-date>
					</v-col>
					<v-col cols="6" lg="3" md="3">
						<s-date label="Fecha Fin"></s-date>
					</v-col>
				</v-row> -->
      <!-- </v-card> -->
      <!-- <v-row> -->
        <v-row justify="center">
            
          <v-col cols="12" md="3">
            <s-select-definition
              :def="1181"
              :dgr="74"
              label="Destino"
              v-model="TypeDestiny"
              clearable
              @getDataInitial="getDataTypeDestiny($event)" 
              @change="loadTank($event)"
            /> 
          </v-col>
          <v-col cols="12" md="3" v-if="$fun.isAdmin()">
            <s-select-definition
              :def="1249"
              label="Tank"  
              add
            />
            <!--TODO: Agregar solo si es admin-->
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            lg="6"
            md="6"
            v-for="item in itemsTank"
            :key="item.TypeTnkID"
          >
            <v-card>
              <v-container fluid grid-list-lg>
                <v-layout row wrap>
                  <v-flex xs="12">
                    <v-card :color="'#ECEFF1'" class="black--text">
                      <v-layout row>
                        <v-flex xs="7">
                          <v-card-title primary-title>
                            <div>
                              <div class="headline">
                                <b> {{ item.TypeTankName }}</b>
                                <p>{{ item.TypeDestinyName }}</p>
                              </div>
                              <div style="font-size: 11px">
                                Hora entrada:

                                <v-chip small :color="'info'">
                                  {{
                                    item.TnkHourEntry == "Invalid date"
                                      ? ""
                                      : item.TnkHourEntry
                                  }}
                                </v-chip>
                              </div>
                              <div style="font-size: 11px">
                                Hora salida:

                                <v-chip small :color="'warning'">
                                  {{
                                    item.TnkHourExit == "Invalid date"
                                      ? "" : item.TnkHourExit }}
                                </v-chip>
                              </div>
                              <div>
                                {{ item.DifferenceMinutes > 0 ? item.DifferenceMinutes : 0 }} Minutos
                              </div>
                              <div>
                                <v-chip
                                  small
                                  :color="dateNow > item.TnkHourExit ? '' : 'success'"
                                  >{{
                                    dateNow > item.TnkHourExit
                                      ? "Proceso Terminado, ME: " +
                                        $fun.isDifferenceMinutes(
                                          dateNow,item.TnkHourExit) : ""
                                  }}</v-chip
                                >
                              </div>
                            </div>
                          </v-card-title>
                        </v-flex>
                        <v-flex xs="5">
                          <v-btn
                            style="
                              border-radius: 100%;
                              height: 54%;
                              width: 38%;
                              margin: auto;
                              display: flex;
                              justify-content: space-evenly;"
                            :color="item.LlpQuantityJaba != null ? 'success' : 'warning' "
                            @click="openModal(item)"
                          >
                            <span class="white--text text-h4"
                              >{{ item.LlpQuantityJaba != null ? item.LlpQuantityJaba : 0 }}
                              
                            </span>
                          </v-btn>
                          <!-- <v-img
                      src="../../../assets/tank.png"
                      height="125px"
                      contain
                    ></v-img> -->
                        </v-flex>
                      </v-layout>
                      <v-divider light></v-divider>
                      <v-card-actions class="pa-3">
                        <v-chip
                          x-small
                          :color="item.TnkState == 1 ? 'error' : 'info'">
                          <b>{{
                            item.TnkState == 1 ? "Ocupado" : "Libre"
                          }}</b>
                        </v-chip>
                        <v-spacer></v-spacer>

                        <v-btn
                          :disabled="item.LlpQuantityJaba == null" 
                          rounded 
                          @click="DialogShowTemperatureEnd(item, 2)"
                          >Terminar</v-btn>
                        <v-divider vertical></v-divider>
                        <v-btn 
                          :disabled="item.LlpQuantityJaba == null" 
                          color="error" 
                          rounded 
                          @click="DialogShowTemperatureEnd(item, 3)"
                          >Anular</v-btn>

                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      <!-- </v-row> -->
    </v-container>

    <v-dialog
      v-if="openDialog"
      v-model="openDialog"
      transition="dialog-bottom-transition"
      persistent
      fullscreen>
      <tank-new :item="items" @close="close()"></tank-new>
    </v-dialog>

    <v-dialog
      v-if="temperatureDialog"
      v-model="temperatureDialog"
      transition="dialog-bottom-transition"
      persistent
      width="400">
         <v-card>
          <s-toolbar  
            color="primary" 
            dark
            close
            @close="temperatureDialog = false"
            label="Terminar Tratamiento"
        >
        </s-toolbar>
           <v-container>
            <v-row>
              <v-col cols="4">
                <s-text 
                  decimal 
                  label="Temperatura° Final"
                  v-model="itemTF"
                  />
              </v-col>
            </v-row>
            <v-row class="text-right">
              <v-col>
                <v-btn color="error" @click="end" >Terminar</v-btn>
              </v-col>
            </v-row>
          </v-container>   
         </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import _sTankService from "../../../services/FreshProduction/PrfTankService";
import TankNew from "./TankNew.vue";

export default {
  components: { TankNew },
  data() {
    return {
      temperatureDialog: false,
      itemsTank: [],
      openDialog: false,
      items: [],
      dateNow: "",
      item: {},
      itemTF: 0,
      TypeDestiny: 0,
      TypeDestinyName: "",
      listTypeDestiny: [],
    };
  },

  created() {
    
    this.dateNow = this.$moment(new Date()).format(
      this.$const.FormatDateTimeDB
    );
  },

  watch: {},

  methods: {

    getDataTypeDestiny(data){
      
      this.listTypeDestiny = data;
      
    },
    loadTank(destin){

      var filteDes = this.listTypeDestiny.filter(x => x.DedValue == this.TypeDestiny);
      
      if(filteDes.length > 0){
        this.TypeDestinyName = filteDes[0].DedAbbreviation;
      } 

      this.itemsTank = [];
      _sTankService.GetTank( this.TypeDestinyName , this.$fun.getUserID()).then((r) => {
        if (r.status == 200) {
         
          this.itemsTank = r.data;

          console.log(this.itemsTank);
          this.itemsTank.forEach((element) => {
            /* if(element.TnkID > 0){ */
            element.TnkHourEntry = this.$moment(element.TnkHourEntry).format(
              this.$const.FormatDateTimeDB
            );
            element.TnkHourExit = this.$moment(element.TnkHourExit).format(
              this.$const.FormatDateTimeDB
            );

            element.DifferenceMinutes =
              this.$fun.isDifferenceMinutes(
                element.TnkHourEntry,
                element.TnkHourExit
              ) * -1; //this.$fun.isDifferenceMinutes(element.HourEntry, element.HourExit)
            /* } */
          });
        }
      });
    },
    close() {
      this.openDialog = false;
      this.loadTank(null);
    },

    openModal(val) {
      this.items = val;
      this.items.TypeDestiny = this.TypeDestiny;
      this.openDialog = true;
    },

    initialize() {

     
    },
    end(){
      /* item.itemsDetail = [{TypeTankID: item.TnkID}]
				console.log(item); */

      this.item.TemperatureEnd = this.itemTF;
      if (this.item.TnkID == null) {
        this.$fun.alert("Tanque se encuentra libre", "warning");
        return;
      }

      if(this.item.TemperatureEnd <= 0){
        this.$fun.alert("Ingres la Temperatura de salida.", "warning");
        return;
      }

      this.$fun
        .alert("Esta seguro de terminar proceso ? ", "question")
        .then((val) => {
          if (val.value) {
            this.item.UsrCreateID = this.$fun.getUserID();
    
            _sTankService.save(this.item, this.$fun.getUserID()).then((r) => {             
              if (r.status == 200) {
                this.temperatureDialog = false;
                this.itemTF = 0;
                this.$fun.alert("Proceso terminado correctamente", "success");
                this.loadTank(null);
              }
            });
          }
        });
    },
    DialogShowTemperatureEnd(item, state) {
        this.item = item;
        this.item.TnkState = state;
        this.temperatureDialog = true;
    },
  },
  mounted(){
    this.initialize();
  }
};
</script>
